import React, { useEffect, useRef, useState } from "react";
import CustomeNavbar from "../../Common/Navbar/CustomeNavbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Home.scss";
import Footer from "../../Common/Footer/Footer";
import ContactUsForm from "./Components/ContactUsForm/ContactUsForm";

const Home = () => {
    const [selectedCategory, setSelectedCategory] = useState("Web Development");
    const heroRef = useRef(null);
    const aboutRef = useRef(null);
    const serviceRef = useRef(null);
    const contactRef = useRef(null);

    useEffect(() => {
        heroRef.current.scrollIntoView()
    }, [])

    const onClickLearnMore = () => {
        contactRef.current.scrollIntoView()
    }

    const onSelectService = (service) => {
        const elem = document.getElementById(service.id)
        if (elem) {
            elem.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        setSelectedCategory(service)
    }

    const allServicesData = [
        { id: 'scroll_id_1', service: "Media and Adverts", url: "images/WebDevelopment.png", description: "We provide Web Adverts services that can range from social media outreach, growth enhancements, brand awareness, and web traffic optimisations." },
        { id: 'scroll_id_2', service: "Content", url: "images/DevOps.png", description: "We provide assistance with content planning, content development, content analytics and other advert programs." },
        { id: 'scroll_id_3', service: "Applications", url: "images/AndroidApplication.png", description: "Our services comprise design, development, and enhancement of web applications that can run on all supported devices." },
        { id: 'scroll_id_4', service: "Graphics Design", url: "images/GraphicDesign.png", description: "Check out our graphic design services - from logo design to print design and everything between. Find a design you'll love!" },
        // { service: "iOS applications", url: "images/iOSApplications.png", description: "Our iOS development services include consulting, product strategy, UX/UI development, testing, and technical delivery." },
        // { service: "Cloud Services", url: "images/CloudServices.png", description: "We aim to provide assistance with developing, migrating or otherwise working on applications in order to ensure that they run capably on cloud platforms." }
    ]


    return <div className="home" ref={heroRef}>
        <div className="heroSection" >
            <CustomeNavbar aboutRef={aboutRef} serviceRef={serviceRef} contactRef={contactRef} />
            <div className="herodetails">
                <div className="heroText">Super business experience for everyone.</div>
                <div className="heroSubText">Track your business, get better results, & be the best revenue of you. Less thinking, more lifting.</div>
                <button className="contactUs" onClick={() => contactRef.current.scrollIntoView({ behavior: "smooth" })}>Contact us</button>
            </div>
            <div className="marketingCategory d-flex justify-content-between">
                {
                    // ["Web Development", "Graphics Design", "DevOps", "Android applications", "iOS applications", "Cloud Services"].map((category, id) => <div key={id} className={category === selectedCategory ? "category selectedCategory" : "category"} onClick={() => setSelectedCategory(category)}>{category}</div>)
                    allServicesData.map((category, id) => {
                        return <div
                            key={id}
                            className={category.id === selectedCategory.id ? "category selectedCategory" : "category"}
                            onClick={() => onSelectService(category)}
                        >
                            {category.service}
                        </div>
                    })
                }
            </div>
        </div>
        <div className="aboutBlueOcean" ref={aboutRef}>
            <div className="aboutDetails">
                <div className="aboutBlueOceanText">About Blue Ocean</div>
                <div className="caretiveAgency">An innovation focused business enabler</div>
                <div className="description">
                    What does that mean? It means that all our solutions begin with brand research and all of our work is catered to enhance and work with the brand at hand. It means we are always exploring the new, always innovating, with a focus on recent developments.
                </div>
            </div>
            <div className="aboutImage">
                <img src="images/aboutBlueOcean.png" alt="about" />
            </div>
        </div>
        <div className="ourServices" ref={serviceRef}>
            <div className="ourServicesText">Our Services</div>
            <div className="services">We Provide these Services</div>
            <div className="allServices">
                {allServicesData.map((service, ind) => <div
                    className="serviceBlock"
                    key={ind}
                    id={service.id}
                    style={{
                        background: `linear-gradient(180deg, rgba(0, 175, 231, 0) 0%, #1A5B70 100%) ,url(${service.url})   
                    `, backgroundSize: 'cover'
                    }}
                >
                    <div className="services">{service.service}</div>
                    {/* <img className="serviceImage" src={service.url} alt="services" /> */}
                    {/* <img className="serviceBottomOverlay" src="images/serviceBottomOverlay.svg" alt="overlay" /> */}
                    <div className="overlay">
                        <div className="serviceName" >{service.service}</div>
                        <div className="text">{service.description}</div>
                        <button className="learnMore" onClick={onClickLearnMore}>Learn more</button>
                    </div>
                </div>
                )}
            </div>
        </div>
        <div className="leaveUsLittleSection" ref={contactRef}>
            <div className="leaveUsLittleText">
                <span>Leave us a little</span>
                <span>info, and we'll be in touch</span>
            </div>
            <div className="contactWithUsSection">
                <div className="contactUsForm">
                    <ContactUsForm />
                </div>
                <div className="contactUsImage">
                    <img src="images/contactUs.png" alt="contact us img" />
                </div>
            </div>
        </div>
        <Footer />
    </div>;
};

export default Home;
import { ErrorMessage, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderComp from "../../../LoaderComponent";
import '../../../../App.css';
import { send } from 'emailjs-com';

const ContactUsForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [msg, setMsg] = useState('Please Wait')
  const notify = (message, isShowError) => {
    if(isShowError) {
      toast.error(message);
    } else{
      toast.success(message);
    }
  }
  const handleContactUs = async (values) => {
      console.log('Proceed to Sending Email')
      // setIsLoading(true)
      // setMsg('Please wait')
      send(
        'service_pt2kaky',
        'template_9wzczme',
        values,
        '0pRzqsrmW5io27TIN'
      )
        .then((response) => {
          notify("Thanks for contacting us! We will get in touch with you shortly.", false)
          console.log('SUCCESS!', response.status, response.text);
        })
        .catch((err) => {
          notify(err, true)
          console.log('FAILED...', err);
        });
  
  }

  const ContactUsSchema = Yup.object().shape({
    fullName: Yup.string().min(6).required("Full name Required"),
    email: Yup.string().email("Invalid email").required("Email is Required"),
    message: Yup.string().required("message is required"),
  });
  
  return (
    <div className="formBlock">
         {isLoading && (
          <LoaderComp msg={msg} isOpen={isLoading} onClose={() => { }} />
        )}
      <div className="contactWithUs">Contact with us</div>
      <Formik
        initialValues={{
          fullName: "",
          subject: "",
          phoneNumber: "",
          email: "",
          message: "",
          to_name: "Blueocean Team",
          reply_to: "blueoceanweb3@gmail.com"
        }}
        validationSchema={ContactUsSchema}
        onSubmit={(values, actions) => {
          if (values) {
            // alert(JSON.stringify(values, null, 2));
            // actions.setSubmitting(false);
            handleContactUs(values)
          }
        }}
      >
        {({ handleChange, handleSubmit, values, errors }) => (
          <form onSubmit={handleSubmit}>
            <input
              className={errors.fullName ? "error" : null}
              value={values.fullName}
              onChange={handleChange}
              placeholder="Full Name*"
              name="fullName"
              type="text"
            />
            <input
              value={values.subject}
              onChange={handleChange}
              placeholder="Subject (optional)"
              name="subject"
              type="text"
            />
            <input
              value={values.phoneNumber}
              onChange={handleChange}
              placeholder="Phone Number (optional)"
              name="phoneNumber"
              type="text"
            />
            <input
              className={errors.email ? "error" : null}
              value={values.email}
              onChange={handleChange}
              placeholder="Email*"
              name="email"
              type="email"
            />
            <textarea
              className={errors.message ? "error" : null}
              value={values.message}
              onChange={handleChange}
              placeholder="Message"
              name="message"
              type="text"
            />
            <button type="submit">Send Message</button>
          </form>
        )}
      </Formik>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default ContactUsForm;

import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import "./CustomeNavbar.scss";

const CustomeNavbar = ({ aboutRef, serviceRef, contactRef }) => {
  const [open, setopen] = useState(false);
  const toggle = () => setopen(!open);
  return (
    <Navbar light expand="md">
      <NavbarBrand href="/">
        <img src="images/Logo.svg" alt="logo" />
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={open} navbar>
        <Nav className="ml-auto" navbar>
          <NavItem onClick={() => aboutRef.current.scrollIntoView({behavior: "smooth"})}>
            About
          </NavItem>
          <NavItem onClick={()=>serviceRef.current.scrollIntoView({behavior: "smooth"})}>
            Services
          </NavItem>
          <NavItem onClick={()=>contactRef.current.scrollIntoView({behavior: "smooth"})}>
            Contact us
          </NavItem>
        </Nav>
        <button className="backCall" onClick={()=>contactRef.current.scrollIntoView({behavior: "smooth"})}>Book a call</button>
      </Collapse>
    </Navbar>
  );
};

export default CustomeNavbar;

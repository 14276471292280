import React from 'react'
import './Footer.scss'

const Footer = () => {
    return (
        <div className='footer d-flex'>
            <div className='thirdPerson'>
                <p>Delivering super business experience</p>
                <p style={{ margin: 0 }}>Address: Blueocean FZE LLC, BLA-SP1-87, AMC- BOULEVARD, AJMAN PO Box - 4422</p>
                <p>Email: <a href="mailto:services@blueoceanadvisory.co">services@blueoceanadvisory.co</a></p>
            </div>
            <div className='socialMedia'>
                {/* <img src="images/Facebook.svg" alt="Facebook" />
                <img src="images/Twitter.svg" alt="Twitter" />
                <img src="images/Linkdien.svg" alt="Linkdien" /> */}
                <img src="images/Logo.svg" alt="logo" />
            </div>
        </div>
    )
}

export default Footer